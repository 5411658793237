import React, { useState, useMemo } from "react";
import { Divider, Textarea, Button, Label } from "@theme-ui/components";
import { InputWrap } from "../../../app/DSL/components/form/StyledInput";
import { useToasts } from "react-toast-notifications";
import { useCompliments } from "../../../app/context/compliments";

export default function AddCompliment({ user }) {
  const { addCompliment } = useCompliments();
  const [compliment, setCompliment] = useState();
  const { addToast } = useToasts();

  const onlySpaces = useMemo(
    () => !compliment?.replace(/\s/g, "").length,

    [compliment]
  );

  return (
    <div style={{ marginTop: "1em" }}>
      <Divider />
      <InputWrap>
        <Label>Leave a compliment</Label>
        <Textarea
          rows={3}
          value={compliment}
          onChange={(e) => setCompliment(e.target.value)}
        ></Textarea>
      </InputWrap>
      <Button
        variant={!compliment || onlySpaces ? "disabled" : "primary"}
        onClick={() => {
          addCompliment({ compliment, forUser: user }).then(() => {
            addToast("Compliment posted", {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 1500,
            });
            setCompliment("");
          });
        }}
      >
        Add compliment
      </Button>
    </div>
  );
}
