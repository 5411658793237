import React, { useMemo } from "react";
import { Card, Divider, Image } from "@theme-ui/components";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";
import MessageList from "../Messages/MessageList";
import { NavLink } from "react-router-dom";
import { useMessages } from "../../../app/context/messages";
import { Ring } from "react-awesome-spinners";
import colours from "../../../app/DSL/styles/colours";
import { primary } from "../../../app/DSL/theme";
import { isBefore } from "date-fns";
import { SeeAllLink } from "../../../app/DSL/components/elements/styled";

export default function UnreadMessagesList() {
  const { messages } = useMessages();

  const messageList = useMemo(
    () =>
      messages
        ? messages
            .sort((a, b) =>
              isBefore(a.datePosted.toDate(), b.datePosted.toDate()) ? 1 : -1
            )
            .filter((s) => !s.isRead)
            .slice(0, 5)
        : [],
    [messages]
  );
  return (
    <Card style={{ position: "relative" }}>
      <BlockHeaderContainer title="Unread messages" />
      {messages ? (
        messageList?.length > 0 ? (
          <div style={{ marginBottom: "3em" }}>
            <MessageList messages={messageList} approval={true} />
          </div>
        ) : (
          // TODO: move to empty state component
          <div style={{ textAlign: "center", marginBottom: "3em" }}>
            <Image
              src="/assets/messages.svg"
              style={{ width: "50%", margin: "auto" }}
            />
            <h4>You've read all the messages for now</h4>
            <p>Click see all to view past messages</p>
          </div>
        )
      ) : (
        <div style={{ textAlign: "center" }}>
          <Ring color={colours[primary].shade["500"]} />
        </div>
      )}
      <SeeAllLink>
        <Divider />
        <NavLink to="/admin-area/messages">See all</NavLink>
      </SeeAllLink>
    </Card>
  );
}
