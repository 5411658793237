import React, { useMemo } from "react";
import { getObjFromLink } from "../../../app/context/helpers";
import {
  StyledLabel,
  TableContainer,
  SeeAllLink,
} from "../../../app/DSL/components/elements/styled";
import { pointColor } from "../../../app/context/points/formatting";
import Table from "../../../app/DSL/components/elements/Table";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";
import { Card, Divider } from "@theme-ui/components";
import { NavLink } from "react-router-dom";

export default function TodaysPointsTable({ pointsLog }) {
  const adminColumns = useMemo(
    () => [
      {
        Header: "User",
        id: "user",
        accessor: (p) => p.userLink,
        Cell: (cell) =>
          getObjFromLink(cell.row.original.userLink).name
            ? getObjFromLink(cell.row.original.userLink).name
            : "-",
        filter: "fuzzyText",
      },
      {
        Header: "Description",
        id: "description",
        accessor: (p) => p.description,
        filter: "fuzzyText",
      },
      {
        Header: "Number of Points",
        id: "num",
        accessor: (p) => (
          <StyledLabel
            colour="orange"
            code={pointColor(p.reason)}
            textColour="white"
          >
            {p.numPoints}
          </StyledLabel>
        ),
        filter: "fuzzyText",
      },
    ],
    []
  );
  return (
    <Card style={{ position: "relative" }}>
      <BlockHeaderContainer title="Todays Points" />
      <div style={{ marginBottom: "3em" }}>
        <TableContainer>
          <Table
            emptyMsg="No points"
            columns={adminColumns}
            data={
              pointsLog
                ? pointsLog.sort(
                    (a, b) =>
                      b.dateReceived?.toDate() - a.dateReceived?.toDate()
                  )
                : []
            }
            defaultRows={5}
            loading={pointsLog === undefined}
            pagination={false}
          />
        </TableContainer>
      </div>
      <SeeAllLink>
        <Divider />
        <NavLink to="/admin-area/points">See all</NavLink>
      </SeeAllLink>
    </Card>
  );
}
