import React, { useState, useEffect } from "react";
import { Card, Divider, Image } from "@theme-ui/components";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";
import { useCompliments } from "../../../app/context/compliments";
import { useMemo } from "react";
import ComplimentList from "../../Teammates/Compliments/ComplimentList";
import { NavLink } from "react-router-dom";
import { Ring } from "react-awesome-spinners";
import colours from "../../../app/DSL/styles/colours";
import { primary } from "../../../app/DSL/theme";
import { isBefore } from "date-fns";
import { SeeAllLink } from "../../../app/DSL/components/elements/styled";

export default function ApprovalComplimentsList() {
  const { getUnapprovedCompliments } = useCompliments();

  const [compliments, setCompliments] = useState();

  useEffect(() => getUnapprovedCompliments(setCompliments), [
    getUnapprovedCompliments,
  ]);

  const complimentList = useMemo(
    () =>
      compliments
        ? compliments
            .filter((c) => !c.isArchived)
            .sort((a, b) =>
              isBefore(a.datePosted.toDate(), b.datePosted.toDate()) ? 1 : -1
            )
            .slice(0, 5)
        : [],
    [compliments]
  );
  return (
    <Card style={{ position: "relative" }}>
      <BlockHeaderContainer title="Compliments awaiting approval" />
      {compliments ? (
        complimentList?.length > 0 ? (
          <div style={{ marginBottom: "3em" }}>
            <ComplimentList compliments={complimentList} approval={true} />
          </div>
        ) : (
          <div style={{ textAlign: "center", marginBottom: "3em" }}>
            <Image
              src="/assets/compliments.svg"
              style={{ width: "45%", margin: "auto" }}
            />
            <h4>You've approved all the compliments for now</h4>
            <p>Click see all to view all compliments</p>
          </div>
        )
      ) : (
        <div style={{ textAlign: "center" }}>
          <Ring color={colours[primary].shade["500"]} />
        </div>
      )}
      <SeeAllLink>
        <Divider />
        <NavLink to="/admin-area/compliments">See all</NavLink>
      </SeeAllLink>
    </Card>
  );
}
