import React, { useMemo } from "react";
import { format } from "date-fns/esm";
import {
  TableContainer,
  StyledLabel,
} from "../../app/DSL/components/elements/styled";
import Table from "../../app/DSL/components/elements/Table";
import { pointColor } from "../../app/context/points/formatting";
import { getObjFromLink } from "../../app/context/helpers";

export default function PointsTable({ pointsLog, admin }) {
  const columns = useMemo(
    () => [
      {
        Header: "Date Received",
        id: "date",
        accessor: (p) => p.dateReceived.toDate(),
        Cell: (cell) =>
          format(
            cell.row.original.dateReceived.toDate(),
            "dd/MM/YYY | hh:mm a"
          ),
        filter: "fuzzyText",
      },
      {
        Header: "Description",
        id: "description",
        accessor: (p) => p.description,
        filter: "fuzzyText",
      },
      {
        Header: "Number of Points",
        id: "num",
        accessor: (p) => (
          <StyledLabel
            colour="orange"
            code={pointColor(p.reason)}
            textColour="white"
          >
            {p.numPoints}
          </StyledLabel>
        ),
        filter: "fuzzyText",
      },
    ],
    []
  );
  const adminColumns = useMemo(
    () => [
      {
        Header: "Date Received",
        id: "date",
        accessor: (p) => p.dateReceived.toDate(),
        Cell: (cell) =>
          format(
            cell.row.original.dateReceived.toDate(),
            "dd/MM/YYY | hh:mm a"
          ),
        filter: "fuzzyText",
      },
      {
        Header: "User",
        id: "user",
        accessor: (p) => p.userLink,
        Cell: (cell) => getObjFromLink(cell.row.original.userLink).name,
        filter: "fuzzyText",
      },
      {
        Header: "Description",
        id: "description",
        accessor: (p) => p.description,
        filter: "fuzzyText",
      },
      {
        Header: "Number of Points",
        id: "num",
        accessor: (p) => (
          <StyledLabel
            colour="orange"
            code={pointColor(p.reason)}
            textColour="white"
          >
            {p.numPoints}
          </StyledLabel>
        ),
        filter: "fuzzyText",
      },
    ],
    []
  );
  return (
    <TableContainer>
      <Table
        emptyMsg="No points"
        columns={admin ? adminColumns : columns}
        data={
          pointsLog
            ? pointsLog.sort(
                (a, b) => b.dateReceived?.toDate() - a.dateReceived?.toDate()
              )
            : []
        }
        defaultRows={10}
        loading={pointsLog === undefined}
      />
    </TableContainer>
  );
}
