import React, { useState } from "react";
import { InputWrap } from "../../app/DSL/components/form/StyledInput";
import { Label, Textarea, Button } from "@theme-ui/components";
import { useToasts } from "react-toast-notifications";
import { useMessages } from "../../app/context/messages";
import Select from "react-select";
import { customStyles } from "../../app/forms/Form";
import { platforms } from "../../app/context/data/platforms";

export default function AddMessage() {
  const [type, setType] = useState();
  const [content, setContent] = useState("");
  const [platform, setPlatform] = useState();
  const { addMessage } = useMessages();
  const { addToast } = useToasts();

  const platformOptions = [
    { label: "HQ", value: "HQ" },
    ...platforms

      .filter((p) => p.active)
      .map((p) => ({
        label: p.title,
        value: p.name,
      })),
  ];

  const typeOptions = [
    { label: "I need help", value: "help" },
    { label: "I have a feature suggestion", value: "feature" },
    { label: "I have encountered a problem", value: "bug" },
    { label: "Other", value: "other" },
  ];
  return (
    <div>
      <p>
        Do you need help with something, maybe you have a suggestion? Send us
        any feedback or suggestions you have about the{" "}
        {process.env.REACT_APP_SITE_TITLE} platform
      </p>
      <div>
        <InputWrap>
          <Label>Reason</Label>
          <Select
            styles={customStyles}
            isSearchable
            options={typeOptions}
            value={type}
            placeholder="Select a reason"
            onChange={(option) => setType(option)}
          />
        </InputWrap>
        <InputWrap>
          <Label>Platform</Label>
          <Select
            styles={customStyles}
            isSearchable
            options={platformOptions}
            value={platform}
            placeholder="Select a platform"
            onChange={(option) => setPlatform(option)}
          />
        </InputWrap>
        <InputWrap>
          <Label>Message</Label>
          <Textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
          ></Textarea>
        </InputWrap>
        <InputWrap>
          <Button
            onClick={() => {
              addMessage({ type, platform, content }).then(() => {
                addToast("Your message has been sent", {
                  appearance: "success",
                  autoDismiss: true,
                  autoDismissTimeout: 1500,
                });
                setContent("");
                setType(null);
                setPlatform(null);
              });
            }}
          >
            Send Message
          </Button>
        </InputWrap>
      </div>
    </div>
  );
}
